<template>
  <div>
    <a-modal
        :width="700"

        :destroyOnClose="true"
        :confirm-loading="confirmLoading"
        :dialog-style="{ top: '20px' }"
        :visible="visible" :title="title" okText="Submit" @ok="handleOk" @cancel="cancelForm">
        <div class="app-user-profile">

          <!-- Header Background Image -->
          <!--		<div class="profile-nav-bg" style="background-image: url('images/bg-profile.jpg')"></div>-->
          <!-- / Header Background Image -->

          <!-- User Profile Card -->
          <a-card :bordered="true" class="header-solid h-full" v-if="!loading" :loading="loading" :bodyStyle="{padding: 20,}">
            <template #title>
              <a-row type="flex" align="middle">
                <a-col :span="24" :md="12" class="col-info" style="display: flex;align-items: center">
                  <a-avatar :size="74" v-if="profile.image" :src="url+profile.image" />
                  <a-avatar :size="74" v-else  style="font-size:28px;background-color: #1890ff">{{ profile.name|getNameForAvatar}}</a-avatar>
                  <div class="avatar-info" style="margin-left: 5px">
                    <h4 class="font-semibold m-0">{{ profile.name }}
                      <!--                <a-tooltip placement="top" slot="extra">-->
                      <!--                  <template slot="title">-->
                      <!--                    <span>Edit Info</span>-->
                      <!--                  </template>-->
                      <!--                  <a-button type="link"  @click="showModal=true">-->
                      <!--                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                      <!--                      <path class="fill-muted" d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z" fill="#111827"/>-->
                      <!--                      <path class="fill-muted" d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z" fill="#111827"/>-->
                      <!--                    </svg>-->
                      <!--                  </a-button>-->
                      <!--                </a-tooltip>-->
                    </h4>
                    <p>{{ profile.email }}</p>
                  </div>

                </a-col>
                <!--					<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">-->
                <!--						<a-radio-group v-model="profileHeaderBtns" size="small">-->
                <!--							<a-radio-button value="overview">OVERVIEW</a-radio-button>-->
                <!--							<a-radio-button value="teams">TEAMS</a-radio-button>-->
                <!--							<a-radio-button value="projects">PROJECTS</a-radio-button>-->
                <!--						</a-radio-group>-->
                <!--					</a-col>-->
              </a-row>
            </template>

            <FormModal v-if="showModal" :visible="showModal" :selectedItem="profile"
                       @formSuccess="formSuccess" @cancel="cancelForm"></FormModal>
            <div class="mb-15">
              {{profile.description}}
            </div>
            <a-descriptions :column="1">
              <a-descriptions-item label="Full Name">
                {{ profile.name }}
              </a-descriptions-item>
              <a-descriptions-item label="Mobile">
                {{ profile.phone }}
              </a-descriptions-item>
              <a-descriptions-item label="Email">
                {{ profile.email }}
              </a-descriptions-item>
              <a-descriptions-item label="Location">
                {{ profile.location }}
              </a-descriptions-item>
              <!--          <a-descriptions-item label="Social">-->
              <!--            <a href="#" class="mx-5 px-5">-->
              <!--              <a-icon type="twitter" style="color: #3EA1EC;" />-->
              <!--            </a>-->
              <!--            <a href="#" class="mx-5 px-5">-->
              <!--              <a-icon type="facebook" style="color: #344E86" />-->
              <!--            </a>-->
              <!--            <a href="#" class="mx-5 px-5">-->
              <!--              <a-icon type="instagram" style="color: #E1306C" />-->
              <!--            </a>-->
              <!--          </a-descriptions-item>-->
            </a-descriptions>
          </a-card>
          <!-- User Profile Card -->
          <a-card :bordered="true" class="header-solid h-full" style="min-height: 400px" v-else>
            <a-row :gutter="[24, 24]">
              <a-col :span="24" >
                <a-skeleton avatar active />
                <a-skeleton active />
                <a-skeleton active />
                <a-skeleton active />
              </a-col>
            </a-row>
          </a-card>
        </div>

    </a-modal>
  </div>
</template>

<script>
import FormModal from '../../views/profile/FormModal'

export default ({
  components: {
    FormModal
  },
  data() {
    return {
      showModal:false,
      url: process.env.VUE_APP_SERVER_URL,

      profile:'',
      // Active button for the "User Profile" card's radio button group.
      profileHeaderBtns: 'overview',
      loading:true
    }
  },
  methods:{

    formSuccess(profile) {
      this.showModal = false;
      this.profile=profile
    },
    cancelForm() {
      this.showModal = false;
    },
  },
  beforeCreate(){
    this.loading=true
    this.$store.dispatch('GET_USER_PROFILE').then(
        (response)=>{
          this.profile=response.data.profile
        }
    )
        .finally(()=>{
          this.loading=false
        })
  }
})

</script>
<style >
.app-user-profile .ant-card-body{
  padding: 16px 30px!important;
}
</style>