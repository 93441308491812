<template>
  <div>
    <a-modal
        :width="900"

        :destroyOnClose="true"
        :confirm-loading="confirmLoading"
        :dialog-style="{ top: '20px' }"
        :visible="visible" :title="title" okText="Submit" @ok="handleOk" @cancel="cancelForm">
      <a-form layout='vertical' :form="form" @submit="handleOk">
        <a-row :gutter="24" type="flex">
          <a-col :span="12" >

          <a-form-item label='Full Name'>
          <a-input
              v-decorator="[
              'name',
               { rules: [{ required: true, message: 'Full Name is required' }] ,
               initialValue:selectedItem?selectedItem.name:'',
              }
            ]"
          />
        </a-form-item>
            </a-col>
          <a-col :span="12" >

          <a-form-item label='Email'>
          <a-input
              v-decorator="[
              'email',
               { rules: [{ required: true, message: 'Email is required' }] ,
               initialValue:selectedItem?selectedItem.email:'',
              }
            ]"
          />
        </a-form-item>
          </a-col>
          <a-col :span="12" >

          <a-form-item label='Phone'>
          <a-input
              v-decorator="[
              'phone',
               { rules: [{ required: true, message: 'Phone is required' }] ,
               initialValue:selectedItem?selectedItem.phone:'',
              }
            ]"
          />
        </a-form-item>
          </a-col>
          <a-col :span="12" >

          <a-form-item label='Password'>
            <a-input-password
              v-decorator="[
              'password',
               {
               initialValue:selectedItem?selectedItem.password:'',
              }
            ]"
          />
        </a-form-item>
          </a-col>
          <a-col :span="24" >

          <a-form-item label='Address'>
            <a-input
              v-decorator="[
              'location',
               {
               initialValue:selectedItem?selectedItem.location:'',
              }
            ]"
          />
        </a-form-item>
          </a-col>
          <a-col :span="24" >

            <a-form-item label='Profile Image'>
              <a-upload
                  :remove="handleRemove" :before-upload="beforeUpload"
                  :default-file-list="fileList"
                  list-type="picture"
                  v-decorator="[
              'image',
                        {
                initialValue:fileList,
              }
            ]"
                  accept=".jpeg,.jpg,.gif,.png"
              >
                <a-button :disabled="fileList.length >0">
                  <a-icon type="upload"/>
                  Upload
                </a-button>
              </a-upload>
            </a-form-item>
          </a-col>
          <a-col :span="24" >
        <a-form-item label='Description'>
          <a-input
              :auto-size="{ minRows: 3, maxRows: 6 }"
              type='textarea'
              v-decorator="['description',{
                initialValue:Object.keys(selectedItem).length!=0?selectedItem.description:'',
              }]"
          />
        </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: ['editMode', 'visible','selectedItem'],
  data() {
    return {
      url: process.env.VUE_APP_SERVER_URL,
      fileList: [],
      confirmLoading: false,
      form: this.$form.createForm(this),
      id:''

      // visible: false,
    };
  },
  computed: {
    title() {
      return 'Update My Profile'
    }
  },
  methods: {
    handleOk(e) {
      e.preventDefault();
      if (this.confirmLoading)
        return
      this.form.validateFields((err, values) => {
        if (!err) {
          // values.id=this.id
          const formData = new FormData();
          formData.append('image', this.fileList[0]);
          formData.append('name', !!values.name?values.name:'')
          formData.append('email', !!values.email?values.email:'')
          formData.append('phone', values.phone)
          formData.append('location', values.location)
          formData.append('description', values.description)
          formData.append('password', !!values.password?values.password:'')

          this.confirmLoading = true
            this.$store.dispatch("UPDATE_USER_PROFILE", {formData:formData})
                .then((res) => {
                  this.form.resetFields()
                  this.$message.success(res.data.message);
                  this.$emit('formSuccess',res.data.data)


                })
                .catch((err) => {
                  // this.$message.error(err.response.data.message);
                })
                .finally(() => {
                  this.confirmLoading = false
                });

          }
      });
    },

    cancelForm(){
      this.fileList=[];
      this.form.resetFields();
      this.confirmLoading=false;
      this.$emit('cancel')
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      if (this.fileList.length < 1)
        this.fileList = [...this.fileList, file];

      return false;
    },
  },
  mounted() {
      // if (this.editMode&&Object.keys(this.selectedItem).length!=0){
      //   this.id=this.selectedItem._id

    if (this.selectedItem.image)
        this.fileList= [
          {
            uid: '-1',
            name: this.selectedItem.image_name,
            status: 'done',
            url: this.url+this.selectedItem.image,
            thumbnail: this.url+this.selectedItem.image,
          },
        ]
    // }
  }
};
</script>
<style>
.blocked {
  pointer-events: none;
  opacity: 0.5;
}
</style>